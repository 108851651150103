import * as React from "react";
import {
  DataGrid,
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridRowId,
} from "@mui/x-data-grid";
import { CopyrightComponent } from "./CopyrightComponent";
import {
  Box,
  Button,
  Container,
  createTheme,
  darken,
  Grid,
  lighten,
  Paper,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Navigation } from "./Navigation";
import { deleteMany, fetchCustomerList, getCSCAdmins } from "../Services";
import { Columns } from "./Columns";
import { statusNameById } from "../common/statusNameById";
import { FilterComponent } from "./FilterComponent";
import { UserListColumns } from "./UserListColumns";
import { formatUser } from "../common/formatUser";
import ScrollingMessage from "./ScrollingMessage";

//const rows = customerList;
const mdTheme = createTheme();
export const UserListing = () => {
  const [cellModesModel, setCellModesModel] =
    React.useState<GridCellModesModel>({});
  const [pageSize, setPageSize] = React.useState<number>(20);
  const [totalRowCount, setTotalRowCount] = React.useState(0);
  const [customerList, getCustomerList] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState<GridRowId[]>([]);

  const [searchString, setSearchString] = React.useState("");

  const [page, setPage] = React.useState(0);

  const getBackgroundColor = (color: string, mode: string) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  const listingCols = UserListColumns();

  const handleSetPage = (newPage: number) => {
    setPage(newPage);
    getCustomerLists({ limit: pageSize, page: newPage });
  };

  const hanglePerPage = (pageSize: number) => {
    setPageSize(pageSize);
    getCustomerLists({ limit: pageSize });
  };

  const handleMultipleDelete = () => {
    const conf = window.confirm("Are you sure to delete?");
    if (conf) {
      deleteMany("/delete-selected-user", selectedRows)
        .then((res) => {
          if (res.status === 200) {
            alert("Deleted Successfully");
            getCustomerLists({ limit: pageSize, page: page });
          }
        })
        .catch((error) => {
          getCustomerLists({ limit: pageSize, page: page });
          alert("Oops, something went wrong");
        });
    }
  };
  const getCustomerLists = (req?: any) => {
    getCSCAdmins(req)
      .then((res) => {
        if (res.status === 200) {
          getCustomerList(formatUser(res.data?.cscadmins));
        } else {
          getCustomerList([]);
        }
      })
      .catch((error) => {
        getCustomerList([]);
      });
  };

  const handleCellClick = React.useCallback((params: GridCellParams) => {
    setCellModesModel((prevModel) => {
      if (!params.isEditable) {
        return false;
      }
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = React.useCallback((newModel: any) => {
    setCellModesModel(newModel);
  }, []);

  React.useEffect(() => {
    getCustomerLists();
  }, []);

  const searchCustomer = () => {
    if (searchString) {
      getCustomerLists({
        keyword: searchString,
      });
    }
  };
  const resetSearch = () => {
    getCustomerLists();
    setSearchString("");
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <Navigation />
        <Box
          component="main"
          maxWidth={"lg"}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
            <Grid container>
              <Grid item xs={12} md={12} lg={12}>
                <ScrollingMessage />
                <Paper>
                  <Grid container sx={{ mb: 2, pt: 2, pr: 2 }}>
                    <Grid item xs={2} md={2} lg={2}>
                      <Typography sx={{ mt: 2, ml: 2 }} variant="subtitle1">
                        {"UCL Admin/Retailer"}
                      </Typography>
                    </Grid>
                    {/* <Grid item xs={3} md={3} lg={4}>
                      <FilterComponent click={handleFilter} />
                    </Grid> */}
                    <Grid item textAlign={"right"} xs={10} md={10} lg={10}>
                      {"Search"}
                      <TextField
                        value={searchString}
                        onChange={(e) => setSearchString(e.target.value)}
                        size="small"
                      />
                      <Button
                        onClick={searchCustomer}
                        sx={{ mt: 0.2, ml: 2 }}
                        variant="contained"
                      >
                        Search
                      </Button>
                      <Button
                        onClick={resetSearch}
                        sx={{ mt: 0.2, ml: 2 }}
                        variant="outlined"
                      >
                        Reset
                      </Button>
                      <Button
                        onClick={handleMultipleDelete}
                        sx={{ ml: 1 }}
                        size="small"
                        variant="contained"
                      >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      height: 600,
                      width: "100%",
                      "& .super-app-theme--Completed, .super-app-theme--Completed:hover":
                        {
                          bgcolor: (theme) =>
                            getBackgroundColor(
                              theme.palette.success.main,
                              theme.palette.mode
                            ),
                        },

                      "& .super-app-theme--Deleted, .super-app-theme--Deleted:hover":
                        {
                          bgcolor: (theme) =>
                            getBackgroundColor(
                              theme.palette.error.main,
                              theme.palette.mode
                            ),
                        },
                    }}
                  >
                    <DataGrid
                      rows={customerList}
                      columns={listingCols}
                      pageSize={pageSize}
                      onPageSizeChange={(newPageSize) =>
                        hanglePerPage(newPageSize)
                      }
                      rowCount={totalRowCount}
                      pagination
                      onPageChange={(newPage) => handleSetPage(newPage)}
                      paginationMode="server"
                      disableSelectionOnClick
                      disableColumnSelector
                      rowsPerPageOptions={[20, 50, 100, 500, 1000]}
                      page={page}
                      disableDensitySelector
                      experimentalFeatures={{ newEditingApi: true }}
                      cellModesModel={cellModesModel}
                      onCellModesModelChange={handleCellModesModelChange}
                      onCellClick={handleCellClick}
                      onSelectionModelChange={(newSelectionArray) => {
                        setSelectedRows(newSelectionArray);
                      }}
                      checkboxSelection
                      hideFooterSelectedRowCount
                      disableColumnFilter
                      // disableColumnMenu
                      //   getRowClassName={(params) =>
                      //     `super-app-theme--${statusNameById(params.row.status)}`
                      //   }
                    />
                  </Box>
                </Paper>
              </Grid>
            </Grid>
            <CopyrightComponent sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
