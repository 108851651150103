import { getHttpError } from "./getHttpError";
import $ from "jquery";

//var uri = "https://localhost:8003/mfs100/"; //Secure
export const mfsClientURL = "https://localhost:8003/mfs100/"; //Non-Secure
export const PostMFS100Client = (method: string, jsonData: any) => {
  var res;
  $.support.cors = true;
  var httpStaus = false;
  $.ajax({
    type: "POST",
    async: false,
    crossDomain: true,
    url: mfsClientURL + method,
    contentType: "application/json; charset=utf-8",
    data: jsonData,
    dataType: "json",
    processData: false,
    success: function (data: any) {
      httpStaus = true;
      res = { httpStaus: httpStaus, data: data };
    },
    //@ts-ignore
    error: function (jqXHR, ajaxOptions, thrownError) {
      res = { httpStaus: httpStaus, err: getHttpError(jqXHR, thrownError) };
    },
  });
  return res;
};
