import { IFingerRequest } from "../type";

export const DefaultFingersprints: IFingerRequest[] = [
  {
    finger_number: 1,
    customer_id: 0,
    image_finger: "",
    isCaptured: false,
  },
  {
    finger_number: 2,
    customer_id: 0,
    image_finger: "",
    isCaptured: false,
  },
  {
    finger_number: 3,
    customer_id: 0,
    image_finger: "",
    isCaptured: false,
  },
  {
    finger_number: 4,
    customer_id: 0,
    image_finger: "",
    isCaptured: false,
  },
  {
    finger_number: 5,
    customer_id: 0,
    isCaptured: false,
    image_finger: "",
  },
];
