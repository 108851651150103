import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import ScoreboardIcon from "@mui/icons-material/Scoreboard";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import LockResetIcon from "@mui/icons-material/LockReset";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { hasAddRetailerAccess } from "../common/commonFunction";
export const MainListItems = () => {
  const role = localStorage.getItem("role");
  return (
    <React.Fragment>
      <ListItemButton href="/retailer">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>
      <ListItemButton href="/retailer/add-customer">
        <ListItemIcon>
          <PersonAddAltIcon />
        </ListItemIcon>
        <ListItemText primary="Add Customer" />
      </ListItemButton>
      <ListItemButton href="/retailer/customers">
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Customers List" />
      </ListItemButton>
      {role && parseInt(role) === 3 && (
        <React.Fragment>
          <ListItemButton href="/retailer/add-points">
            <ListItemIcon>
              <ScoreboardIcon />
            </ListItemIcon>
            <ListItemText primary="Add Points" />
          </ListItemButton>

          <ListItemButton href="/retailer/users">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="UCL Admin/Retailer" />
          </ListItemButton>
          <ListItemButton href="/message">
            <ListItemIcon>
              <NotificationsActiveIcon />
            </ListItemIcon>
            <ListItemText primary="Message" />
          </ListItemButton>
        </React.Fragment>
      )}
      {role && (parseInt(role) === 3 || hasAddRetailerAccess) && (
        <ListItemButton href="/retailer/add-user">
          <ListItemIcon>
            <PersonAddIcon />
          </ListItemIcon>
          <ListItemText primary="Add User" />
        </ListItemButton>
      )}

      <ListItemButton href="/change-password">
        <ListItemIcon>
          <LockResetIcon />
        </ListItemIcon>
        <ListItemText primary="Change Password" />
      </ListItemButton>
    </React.Fragment>
  );
};
