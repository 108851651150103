import { GridRowId } from "@mui/x-data-grid";
import {
  ICustomer,
  IFingerRequest,
  IRetailer,
  IPointReq,
  IUpdateStatusRequest,
} from "../type";
import User from "./UserManagement";
export const getUsers = (params: any) => User.get(params);
export const fetchCustomerList = (req?: any) => User.getCustomers(req);
export const validateLogin = (params: any) => User.login(params);
export const userRegister = (req: IRetailer) => User.register(req);
export const customerRegister = (req: ICustomer) => User.customerRegister(req);
export const updateRegister = (req: ICustomer) => User.updateRegister(req);
export const deleteCustomer = (customerId: string) =>
  User.deleteCustomer(customerId);
export const uploadFingerPrint = (req: IFingerRequest) =>
  User.uploadFingerPrint(req);
export const updateStatus = (req: IUpdateStatusRequest) =>
  User.updateStatus(req);
export const getCustomerPoints = () => User.getCustomerPoints();
export const getCSCAdmins = (req?: any) => User.getCSCAdmins(req);
export const savePoints = (req: IPointReq) => User.savePoints(req);
export const getCscusers = () => User.getCscusers();
export const deleteMany = (url: string, req: GridRowId[]) =>
  User.multipleDelete(url, req);
