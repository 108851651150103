import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import { messages } from "../config";
export const CustomerPointNotAvailableMsg = () => {
  return (
    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
      <Alert sx={{ p: 2 }} severity={"error"}>
        {messages?.regFormMsg}
      </Alert>
    </Box>
  );
};
