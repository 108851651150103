import React from "react";
type IAuth = {
  isLogin?: boolean;
  userId?: string;
  displayName?: string;
  authtoken?: string;
  setAuthenticated: (auth: IAuth) => void;
};
export const AuthContext = React.createContext({
  // isLogin: true,
  // userId: "",
  // displayName: "",
  authtoken: "",
  //@ts-ignore
  setAuthenticated: (auth) => {},
});
