import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import { AddPoints } from "./Components/AddPoints";
import { AddUser } from "./Components/AddUser";
import { CustomerDetails } from "./Components/CustomerDetails";
import { CustomerListing } from "./Components/CustomerListing";
import { CustomerRegister } from "./Components/CustomerRegister";
import { DashboardComponent } from "./Components/DashboardComponent";
import { ImageZoomControls } from "./Components/ImageZoomControls";
import { ChangePassword } from "./Components/ChangePassword";
import { UserListing } from "./Components/UserListing";
import { Message } from "./Components/Message";

export const routers = createBrowserRouter([
  {
    path: "",
    element: <App />,
  },
  {
    path: "/retailer",
    element: <DashboardComponent />,
  },
  {
    path: "/retailer/customer/:id",
    element: <CustomerDetails />,
  },
  {
    path: "image/controll",
    element: <ImageZoomControls />,
  },
  {
    path: "/retailer/add-customer/:id?",
    element: <CustomerRegister />,
  },
  {
    path: "/retailer/customers",
    element: <CustomerListing />,
  },
  {
    path: "/retailer/add-points",
    element: <AddPoints />,
  },
  {
    path: "/retailer/add-user",
    element: <AddUser />,
  },
  {
    path: "/retailer/users",
    element: <UserListing />,
  },
  {
    path: "/message",
    element: <Message />,
  },
  {
    path: "/change-password",
    element: <ChangePassword />,
  },
]);
