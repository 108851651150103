import React from "react";
import "./App.css";

import LoginComponent from "./Components/LoginComponent";

function App() {
  return <LoginComponent />;
}

export default App;
