import {
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { GridRenderCellParams, useGridApiContext } from "@mui/x-data-grid";
import React, { useState } from "react";
import { statusList } from "../common/statusList";
import UserManagement from "../Services/UserManagement";

export const StatusListDropdown = (props: GridRenderCellParams) => {
  const { id } = props;
  const apiRef = useGridApiContext();
  // const [comment, setComment] = useState("");
  const handleChange = async (event: SelectChangeEvent) => {
    await apiRef.current.setEditCellValue({
      id: props.id,
      field: "status",
      value: event.target.value,
    });
    UserManagement.updateStatus({
      customer_id: id.toString(),
      status: event.target.value.toString(),
    })
      .then((res) => {
        if (res) {
          //@ts-ignore
          alert(res.message);
        }
      })
      .catch(() => {
        alert("Something wrong");
      });
  };

  return (
    <FormControl fullWidth>
      <Select name="status" size={"small"} onChange={handleChange}>
        {statusList.map((status) => (
          <MenuItem key={status.id} value={status.id}>
            {status.name}
          </MenuItem>
        ))}
      </Select>
      {/* <TextField
        value={comment}
        name="comment"
        rows={20}
        onChange={(e) => setComment(e.target.value)}
      /> */}
    </FormControl>
  );
};
