import { hasShowFingerAccess } from "./commonFunction";

const fingerPrinterAccess = [1];
const superAdmin = [3];

export const hasFingerPrintListingAccess = (role: number) => {
  return (
    role &&
    (superAdmin.includes(role) ||
      (fingerPrinterAccess.includes(role) && hasShowFingerAccess))
  );
};
