import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { Navigation } from "./Navigation";
import {
  Box,
  Button,
  Container,
  Divider,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { AuthContext } from "../Services/AuthContext";
import { useContext, useEffect, useState } from "react";
import UserManagement from "../Services/UserManagement";

const mdTheme = createTheme();
export const Message = () => {
  const { authtoken, setAuthenticated } = useContext(AuthContext);
  const [inputValue, handleChange] = useState("");

  const handleSubmit = () => {
    UserManagement.addAnnouncement({
      message: inputValue,
    })
      .then((res) => {
        if (res) {
          alert("Message added successfully");
        }
      })
      .catch((error) => {
        alert("Something is wrong");
      });
  };
  useEffect(() => {
    UserManagement.getAnnouncement()
      .then((res) => {
        //@ts-ignore
        handleChange(res.data?.message);
      })
      .catch((error) => {
        handleChange("");
      });
  }, []);

  return (
    <AuthContext.Provider value={{ authtoken, setAuthenticated }}>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: "flex" }}>
          <Navigation />
          <Box
            component="main"
            maxWidth={"lg"}
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === "light"
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Container maxWidth="lg" sx={{ mt: 10, mb: 4 }}>
              <Grid container>
                <Grid item xs={12} md={12} lg={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: 240,
                    }}
                  >
                    <Typography variant="subtitle1">{"Add message"}</Typography>
                    <Divider sx={{ mt: 1 }} />
                    <Grid
                      container
                      rowSpacing={1}
                      sx={{ mt: 2 }}
                      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                    >
                      <Grid
                        item
                        sx={{ mt: 1 }}
                        xs={12}
                        lg={12}
                        md={12}
                        textAlign={"left"}
                      >
                        <TextareaAutosize
                          aria-label="Enter Message"
                          value={inputValue}
                          maxRows={10}
                          minRows={5}
                          cols={100}
                          aria-rowcount={5}
                          name="name"
                          onChange={(e) => {
                            handleChange(e.target.value);
                          }}
                          placeholder="..."
                        />
                      </Grid>
                      <Grid
                        item
                        sx={{ mt: 1 }}
                        xs={3}
                        lg={3}
                        textAlign={"left"}
                      >
                        <Button variant="outlined" onClick={handleSubmit}>
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Box>
      </ThemeProvider>
    </AuthContext.Provider>
  );
};
