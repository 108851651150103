import React, { useRef } from "react";
import { Alert, Box, Grid, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import {
  customerRegister,
  getCustomerPoints,
  updateRegister,
} from "../Services";
import { IMessage } from "./CustomerRegister";
import { ICustomer } from "../type";

interface IPassed {
  setMessage: (msg: IMessage) => void;
  message: IMessage;
  data?: ICustomer;
}
export const RegistrationForm = ({ setMessage, message, data }: IPassed) => {
  //const initialValue = dayjs("14-01-2023").format("DD/MM/YYYY");
  // const [customerId, setCustomerId] = useState();
  const [dob, setDOB] = React.useState<Dayjs | null>(null);
  const [formValues, setFormValue] = React.useState<ICustomer>(data || {});
  const [errors, setErrors] = React.useState<ICustomer>({});
  const [isProcessing, checkProcessing] = React.useState(false);
  const [uploadedFiles, handleFileUploadState] = React.useState({
    customer_photo: "",
    aadhar_number_front: "",
    aadhar_number_back: "",
    birth_doc: "",
    other_doc: "",
  });
  const formRef = useRef(null);
  const btnLabel = data?.id ? "Update" : "Register";
  const handleFileUpload = (e: any) => {
    let file = e.target.files[0];
    const name = e.target.name;
    let reader = new FileReader();
    reader.onloadend = function () {
      handleFileUploadState({ ...uploadedFiles, [name]: reader.result });
    };
    reader.readAsDataURL(file);
  };
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    let registerReq = {};
    if (data?.id) {
      //@ts-ignore
      formData.append("id", data?.id);
    }

    //@ts-ignore
    for (const value of formData.entries()) {
      if (
        (value[0] === "aadhar_number_back" ||
          value[0] === "aadhar_number_front" ||
          value[0] === "birth_doc" ||
          value[0] === "customer_photo") &&
        value[1]["name"]
      ) {
        registerReq = { ...registerReq, [value[0]]: value[1] };
      } else {
        if (value[1]) {
          registerReq = { ...registerReq, [value[0]]: value[1] };
        }
      }
    }

    registerReq = { ...registerReq, ...uploadedFiles };
    const reqValue = { ...registerReq, id: data?.id };
    if (registerReq || reqValue) {
      checkProcessing(true);
      if (btnLabel === "Update") {
        updateRegister(reqValue)
          .then((res) => {
            if (res.status === 200) {
              //@ts-ignore
              localStorage.setItem("customerId", res.data.customer.id);

              setMessage({
                type: "success",
                message: "Details are successfully updated.",
              });
              checkProcessing(false);
              setErrors({});
              // setCustomerId(res.data.custoer.id);
            }
          })
          .catch((error) => {
            if (error?.response?.data?.errors) {
              setErrors(error?.response?.data?.errors);
            }
            checkProcessing(false);
            setMessage({
              type: "error",
              message: "Oops, Pls check form validation",
            });
          });
      } else {
        getCustomerPoints().then((result) => {
          //@ts-ignore
          if (result?.data?.available_points) {
            localStorage.setItem(
              "totalPoints",
              //@ts-ignore
              result?.data?.available_points
            );
            //@ts-ignore
            if (result?.data?.available_points > 0) {
              customerRegister(registerReq)
                .then((res) => {
                  if (res.status === 200) {
                    //@ts-ignore
                    localStorage.setItem("customerId", res.data.customer.id);

                    setMessage({
                      type: "success",
                      message:
                        "Registeration is successfull. Please scan finger now",
                    });
                    checkProcessing(false);
                    setErrors({});
                    // setCustomerId(res.data.custoer.id);
                  }
                })
                .catch((error) => {
                  if (error?.response?.data?.errors) {
                    setErrors(error?.response?.data?.errors);
                  }
                  checkProcessing(false);
                  setMessage({
                    type: "error",
                    message: "Oops, Pls check form validation",
                  });
                });
            } else {
              alert(
                "You don't have available points to add customer. Please contact administrator"
              );
              window.location.reload();
            }
          }
        });
      }
    }
  };
  const formChange = (event: React.FormEvent<HTMLFormElement>) => {
    const formData = new FormData(event.currentTarget);
    let registerReq = {};
    //@ts-ignore
    for (const value of formData.entries()) {
      if (value[1]) {
        registerReq = { ...registerReq, [value[0]]: value[1] };
        setFormValue(registerReq);
      }
    }
  };

  const isSubmitBtnDisabled =
    !formValues.name ||
    !formValues.mobile ||
    formValues.mobile?.length !== 10 ||
    !formValues.aadhar_number ||
    formValues.aadhar_number?.length !== 12;

  return (
    <React.Fragment>
      <Box
        component="form"
        onChange={formChange}
        onSubmit={handleSubmit}
        id="customerForm"
        ref={formRef}
        noValidate
        sx={{ mt: 1 }}
      >
        {message.type && message.message !== "" && (
          <Alert sx={{ mb: 2 }} severity={message.type}>
            {message.message}
          </Alert>
        )}
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={4} lg={3}>
            <label>Name</label>
            <TextField
              required
              value={formValues.name}
              error={!!errors?.name || !formValues.name}
              helperText={errors?.name ? errors?.name[0] : ""}
              name="name"
              fullWidth
              placeholder="..."
              size="small"
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <label>Mobile</label>
            <TextField
              required
              helperText={errors?.mobile ? errors?.mobile[0] : ""}
              value={formValues.mobile}
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              error={
                !!errors?.mobile ||
                !formValues.mobile ||
                formValues.mobile.length !== 10
              }
              name="mobile"
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={4} lg={3}>
            <label>Date of Birth</label>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                disableFuture
                //inputFormat={dayjs().format("DD/MM/YYYY")}
                value={dob || formValues.dob}
                onChange={(newValue) => {
                  //@ts-ignore
                  setDOB(newValue);
                }}
                renderInput={(params) => (
                  <TextField size="small" name="dob" {...params} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={4} lg={3}>
            <label>Aadhar Number</label>
            <TextField
              required
              helperText={errors?.aadhar_number ? errors?.aadhar_number[0] : ""}
              value={formValues.aadhar_number}
              error={
                !!errors?.aadhar_number ||
                !formValues.aadhar_number ||
                formValues.aadhar_number.length !== 12
              }
              name="aadhar_number"
              fullWidth
              size="small"
            />
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={1}
          sx={{ mt: 2 }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item xs={12} lg={2.5}>
            <label>Customer Photo</label>
            <TextField
              onChange={handleFileUpload}
              name="customer_photo"
              fullWidth
              type={"file"}
              size="small"
            />
          </Grid>

          <Grid item xs={12} lg={2.5}>
            <label>Aadhar Front</label>
            <TextField
              onChange={handleFileUpload}
              name="aadhar_number_front"
              type={"file"}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} lg={2.5}>
            <label>Aadhar Back</label>
            <TextField
              onChange={handleFileUpload}
              name="aadhar_number_back"
              type={"file"}
              fullWidth
              size="small"
            />
          </Grid>
          <Grid item xs={12} lg={2}>
            <label>Birth Certificate</label>
            <TextField
              onChange={handleFileUpload}
              name="birth_doc"
              fullWidth
              type={"file"}
              size="small"
            />
          </Grid>
          <Grid item xs={12} lg={2.5}>
            <label>Other Doc</label>
            <TextField
              onChange={handleFileUpload}
              name="other_doc"
              fullWidth
              type={"file"}
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} sx={{ mt: 2 }}>
          <Grid item lg={12}>
            <label>Note</label>
            <br />
            <textarea
              value={formValues?.notes}
              style={{ width: "100%" }}
              rows={5}
              name="notes"
              placeholder="..."
            />
          </Grid>
        </Grid>
        <Grid container rowSpacing={1} sx={{ mt: 2 }}>
          <Grid textAlign={"right"} item lg={12}>
            <Button
              disabled={Boolean(isSubmitBtnDisabled) || isProcessing}
              type="submit"
              variant="outlined"
            >
              {isProcessing ? "Please Wait..." : btnLabel}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};
