import { Link, Typography } from "@mui/material";

export const CopyrightComponent = (props: any) => {
  return null;
  // return (
  //   <Typography
  //     variant="body2"
  //     color="text.secondary"
  //     align="center"
  //     {...props}
  //   >
  //     {"Copyright © "}
  //     {new Date().getFullYear()}
  //   </Typography>
  // );
};
