import UserManagement from "../Services/UserManagement";
import { useEffect, useState } from "react";

export default function ScrollingMessage() {
  const [message, setMessage] = useState("");

  useEffect(() => {
    UserManagement.getAnnouncement()
      .then((res) => {
        //@ts-ignore
        setMessage(res.data?.message);
      })
      .catch((error) => {
        setMessage("");
      });
  }, [message]);
  return (
    <>
      {(message && (
        <div className="bounce">
          <p>{message} </p>
        </div>
      )) ||
        null}
    </>
  );
}
